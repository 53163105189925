<template>
	<article>
		<hero-image
			:alt="hero.alt"
			:heading="hero.heading"
			:img-small="hero.imgSmall"
			:img-large="hero.imgLarge"
		/>
		<v-container class="py-0">
			<v-row no-gutters align="center">
				<v-col :order="$vuetify.breakpoint.xsOnly ? 'last' : null" cols="12" sm="7">
					<breadcrumbs :crumbs="crumbs" />
				</v-col>
				<v-col cols="12" sm="5">
					<social :meta="meta" />
				</v-col>
			</v-row>
		</v-container>
		<v-container class="content" tag="section">
			<v-row>
				<v-col cols="12">
					<p class="blockquote">
						A comprehensive neuropsychological assessment will examine multiple domains of intellectual and cognitive functioning. The assessment
						will be tailored to the referral question and the child.
					</p>
					<p>The skills which are typically assessed are:</p>
					<ul>
						<li>General intellectual ability.</li>
						<li>Academic ability.</li>
						<li>Attention and concentration.</li>
						<li>Learning and memory.</li>
						<li>Language.</li>
						<li>Executive functions.</li>
						<li>Adaptive behaviours.</li>
						<li>Social skills.</li>
						<li>Emotional control.</li>
					</ul>
					<p>
						This assessment will give an indication of the child's cognitive strengths and any areas of difficulties. Following the assessment, a
						report and detailed recommendations are provided to help the child reach their full potential.
					</p>
				</v-col>
				<v-col cols="12">
					<cta :color="$vuetify.theme.themes.light.comprehensive" icon="$vuetify.icons.comprehensive" />
				</v-col>
			</v-row>
		</v-container>
	</article>
</template>

<script>
import { _MxAppViewClass, _MxMeta } from '@/mixins/global';
import Breadcrumbs from '@/components/breadcrumbs/Breadcrumbs';
import Cta from '@/components/cta/Cta';
import HeroImage from '@/components/image/HeroImage';
import Social from '@/components/social/Social';
export default {
	name: 'ComprehensiveNeuropsychology',
	components: {
		Breadcrumbs,
		Cta,
		HeroImage,
		Social
	},
	mixins: [_MxAppViewClass, _MxMeta],
	data() {
		return {
			crumbs: [
				{
					text: 'home',
					disabled: false,
					to: '/'
				},
				{
					text: 'services',
					disabled: false,
					to: '/services'
				},
				{
					text: this.$route.name,
					disabled: true
				}
			],
			hero: {
				alt: this.$route.name,
				heading: this.$route.name,
				imgSmall: 'hero-06-small',
				imgLarge: 'hero-06-large'
			},
			meta: {
				description: 'Comprehensive Neuropsychological Assessment',
				title: 'Comprehensive Neuropsychological Assessment for your child or adolescent from Sydney Kids Neuropsychology.'
			}
		};
	}
};
</script>
